import React from 'react';

import styles from './main-header.module.scss';

import logoImage from './logo.svg';

export const MainHeaderPublic = () => {
  return (
    <div className={styles.mainHeader}>
      <img src={logoImage} alt="logo" className={styles.logo} />
    </div>
  );
};
