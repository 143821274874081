import React from 'react';
import { Outlet } from 'react-router-dom';

import { PageLoader } from './components/loader/page-loader';
import { TopBar } from './components/top-bar/top-bar';
import { Config } from './models/config';
import { Footer } from './components/footer/footer';
import { MainHeaderPublic } from './components/main-header/main-header-public';

interface Props {
  config: Config | null;
}

export const PublicPage: React.FC<Props> = ({ config }) => {
  return (
    <div>
      <header>
        <TopBar />
        <MainHeaderPublic />
      </header>
      <main>
        <React.Suspense fallback={<PageLoader />}>
          <Outlet />
        </React.Suspense>
      </main>
      <Footer config={config} />
    </div>
  );
};
