export const SLUG = {
  LOGIN: 'login',
  PASSWORD_RESET: 'password-reset',
  NEWS: 'news',
  NEWS_CREATE: 'create',
  NEWS_EDIT: 'edit',
  INVOICES: 'invoices',
  PROFORMAS: 'proformas',
  BASKET: 'basket',
  BASKET_ORDER_CREATED: 'success',
  ORDERS: 'orders',
  SEARCH_RESULTS: 'search',
  ITEM: 'item',
  DOWNLOADS: 'downloads',
  PRICE_LIST: 'price-list',
  STOCK: 'stock',
  QUOTATIONS: 'quotation',
  QUOTATION_ORDER_CREATED: 'success',
  PANEL_CONFIGURATOR: 'panel-configurator',
  ADMIN: 'admin',
  CUSTOMER_LIST: 'customer-list',
  NOTIFICATIONS: 'notifications',
  NOTIFICATIONS_CREATE: 'create',
  CONFIG: 'config',
  PROFILE: 'profile',
  PROFILE_GENERAL_INFO: 'general',
  PROFILE_ADDRESSES: 'addresses',
  ID: ':id',
};

export const PATH = {
  ROOT_PAGE: '/',
  LOGIN_PAGE: `/${SLUG.LOGIN}`,
  PASSWORD_RESET_REQUEST_PAGE: `/${SLUG.PASSWORD_RESET}`,
  PASSWORD_RESET_PAGE: `/${SLUG.PASSWORD_RESET}/${SLUG.ID}`,
  NEWS_PAGE: `/${SLUG.NEWS}`,
  NEWS_CREATE_PAGE: `/${SLUG.NEWS}/${SLUG.NEWS_CREATE}`,
  NEWS_EDIT_PAGE: `/${SLUG.NEWS}/${SLUG.ID}/${SLUG.NEWS_EDIT}`,
  INVOICES_PAGE: `/${SLUG.INVOICES}`,
  PROFORMAS_PAGE: `/${SLUG.INVOICES}/${SLUG.PROFORMAS}`,
  BASKET_PAGE: `/${SLUG.BASKET}`,
  BASKET_ORDER_CREATED_PAGE: `/${SLUG.BASKET}/${SLUG.BASKET_ORDER_CREATED}`,
  ORDERS_PAGE: `/${SLUG.ORDERS}`,
  ORDER_PAGE: `/${SLUG.ORDERS}/${SLUG.ID}`,
  SEARCH_RESULTS_PAGE: `/${SLUG.SEARCH_RESULTS}`,
  ITEM_PAGE: `/${SLUG.ITEM}/${SLUG.ID}`,
  DOWNLOADS_PAGE: `/${SLUG.DOWNLOADS}`,
  PRICE_LIST_PAGE: `/${SLUG.DOWNLOADS}/${SLUG.PRICE_LIST}`,
  STOCK_PAGE: `/${SLUG.DOWNLOADS}/${SLUG.STOCK}`,
  QUOTATIONS_PAGE: `/${SLUG.QUOTATIONS}`,
  QUOTATION_PAGE: `/${SLUG.QUOTATIONS}/${SLUG.ID}`,
  QUOTATION_ORDER_CREATED_PAGE: `/${SLUG.QUOTATIONS}/${SLUG.ID}/${SLUG.QUOTATION_ORDER_CREATED}`,
  PANEL_CONFIGURATOR_PAGE: `/${SLUG.PANEL_CONFIGURATOR}`,
  ADMIN_PAGE: `/${SLUG.ADMIN}`,
  CUSTOMER_LIST_PAGE: `/${SLUG.ADMIN}/${SLUG.CUSTOMER_LIST}`,
  NOTIFICATIONS_PAGE: `/${SLUG.ADMIN}/${SLUG.NOTIFICATIONS}`,
  NOTIFICATION_PAGE: `/${SLUG.ADMIN}/${SLUG.NOTIFICATIONS}/${SLUG.ID}`,
  NOTIFICATIONS_CREATE_PAGE: `/${SLUG.ADMIN}/${SLUG.NOTIFICATIONS}/${SLUG.NOTIFICATIONS_CREATE}`,
  CONFIG_PAGE: `/${SLUG.ADMIN}/${SLUG.CONFIG}`,
  PROFILE_PAGE: `/${SLUG.PROFILE}`,
  PROFILE_GENERAL_INFO: `/${SLUG.PROFILE}/${SLUG.PROFILE_GENERAL_INFO}`,
  PROFILE_ADDRESSES: `/${SLUG.PROFILE}/${SLUG.PROFILE_ADDRESSES}`,
};
