import React from 'react';
import { generatePath, Navigate, Route, Routes } from 'react-router-dom';

import { PATH, SLUG } from './constants/path';
import { Customer } from './models/customer';
import { AppLoader } from './components/loader/app-loader';
import { getConfig } from './api/public';
import { AppContext, AppContextValue } from './app-context';
import { Config } from './models/config';
import { handleError } from './utils/handle-error';
import { Terms } from './components/terms/terms';
import { PublicPage } from './public-page';

const PanelConfiguratorPage = React.lazy(() => import('app/pages/panel-configurator-page/panel-configurator-page'));
const NotFoundPage = React.lazy(() => import('./pages/not-found-page/not-found-page'));

const App: React.FC = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [config, setConfig] = React.useState<Config | null>(null);

  const handleReloadCustomerDetails = (onResult: (customer: Customer | null) => void) => {
    onResult(null);
  };

  const handleReloadConfig = (onResult: (config: Config | null) => void) =>
    getConfig()
      .then((config) => {
        onResult(config);
        setConfig(config);
      })
      .catch(handleError);

  const appContextValue = React.useMemo<AppContextValue>(
    () => ({
      customerDetails: null,
      config,
      reloadCustomerDetails: handleReloadCustomerDetails,
      reloadConfig: handleReloadConfig,
    }),
    [config]
  );

  React.useEffect(() => {
    Promise.all([getConfig().then(setConfig)]).finally(() => setIsLoaded(true));
  }, []);

  if (!isLoaded) {
    return <AppLoader />;
  }

  return (
    <AppContext.Provider value={appContextValue}>
      <Routes>
        <Route element={<PublicPage config={config} />}>
          <Route index={true} element={<Navigate to={generatePath(PATH.PANEL_CONFIGURATOR_PAGE)} replace={true} />} />
          <Route path={SLUG.PANEL_CONFIGURATOR} element={<PanelConfiguratorPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
      <Terms config={config} />
    </AppContext.Provider>
  );
};

export default App;
